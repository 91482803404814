
@font-face {
  font-family: avenir;
  src: url(../src/assets/avenir_ff/AvenirLTStd-Black.otf);
}

div {
  font-family: avenir;
}
* {
  box-sizing: border-box;
}
@media only screen and (min-device-width: 280px) {
  body,
  html {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-family: avenir;
    height: 100vh;
    max-width: 100%;
    overflow-x: hidden;
    border-left: 1px solid grey;

    /* background-color: rgba(122, 33, 117, 0.048); */
  }
}
.desktop-container {
  margin: 0;
  border-left: 1px solid grey;
  /* background-color: rgba(122, 33, 117, 0.048); */
}

/*...........................*/

a {
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
}
.top-menu-Container {
  border-bottom: 1px solid rgba(122, 33, 117, 0.048);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  /* background-color: rgba(122, 33, 117, 0.048); */
}

/*........ LOGO .......*/
.logo-Container {
  width: 100px;
}
.logo-img-container {
  width: 20px;
}
.logo-img {
  width: 100px;
}
/*........... Footer .........*/
.top-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 10px 10px 10px 0;
}
.top-footer-list li {
  margin: 5px 0;
  font-weight: bold;
  color: #454646d5;
}
/*........ FOOTER LOGO .......*/
.footer-logo-Container {
  font-size: 16px;
  font-weight: 700;
  padding-top: 30px;
}
.footer-logo-Container {
  width: 100px;
}
.footer-logo-img-container {
  width: 0px;
}
.footer-logo-img {
  width: 70px;
}

/*........ Home Page ........*/
.HomePage--wrapper-con {
  display: flex;
  justify-content: center;
}
.HomePage--wrapper {
  /* background-color: #FFFFFF; */
  width: 100%;
  max-width: 1440px;
  min-height: 100vh;
  /* height: 1366px; */
  position: relative;
  border-left: 1px solid grey;
}
.HomePage--Top-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #800080bd;
  padding: 50px 50px 70px 50px;
  position: relative;
  z-index: 1;
}
.HomePage--Top-layout {
  background-image: url(./images/lagos-techie-IgUR1iX0mqM-unsplash.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Homepage--year {
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
  /* margin-bottom: 7px; */
  letter-spacing: 10px;
}
.Homepage--role {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 5px;
  text-transform: uppercase;
}
.Homepage--Leadership {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 5px;
  text-transform: uppercase;
}
.Badge-logo {
  width: 100px;
  position: absolute;
  right: 50px;
  top: 20px;
}
.Badge-img-wrapper {
  margin-top: 30%;
}
.Badge-img {
  width: 160px;
}

.HomePage--content-container {
  display: flex;
  flex-wrap: wrap;
}
/* .HomePage--content-container .HomePage--content-card{

} */
.HomePage--content-card1 {
  flex: 8;
  padding: 0 30px 10px 50px;
  /* display: flex;
text-align: center;
justify-content: center; */
  color: #212529;
}
.performers {
  font-size: 26px;
  font-weight: 900;
  color: #800080;
}
.HomePage--content-card2 {
  flex: 4;
  margin-top: -20px;
  width: fit-content;
  height: fit-content;
}
.learning-list {
  margin-left: -30px;
}
.HomePage--content-date-con {
  display: flex;
  align-items: center;
}
.HomePage--content-date-icon {
  font-size: 40px;
  margin-right: 7px;
}
.HomePage--content-date,
.HomePage--content-date-day-con {
  margin-right: 5px;
}
.HomePage--content-date,
.HomePage--content-date-year {
  font-size: 34px;
  font-weight: 600;
}
.HomePage--content-date-con {
  /* justify-content: center;*/
  /* padding: 30px 10px 10px 50px;  */
}
sup {
  font-size: 20px !important;
  font-weight: normal !important;
}
.HomePage--content-date-day-con {
  line-height: 30px;
  font-size: 30px;
  padding: 0 5px;
}
.HomePage--content-date-day-fri {
  margin-left: 5px;
}

.HomePage--content-button-con {
  padding: 30px 0 10px 0px;
  /* text-align: center; */
}
.HomePage--content-button-contain {
  padding: 0 0 0 50px;
  /* text-align: center; */
}
.HomePage--content-button-contain2 {
  padding: 10px 0;
  /* text-align: center; */
}
.HomePage--content-button-con button {
  border: none;
  padding: 18px;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  border-radius: 7px;
  background-color: #800080;
  color: #fff;
  /* box-shadow: 0 0 20px 4px rgba(94, 94, 94, 0.466); */
}
.HomePage--content-button-con button:hover {
  background-color: #580258;
  box-shadow: 0 0 20px 4px rgba(94, 94, 94, 0.466);
  /* transition: background-color 1000ms linear; */
  transition: 2s;
}
#ivyarcLink {
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
  color: #800080;
}
.HomePage--content-Placement {
  display: flex;
  align-items: center;
  /* margin: 10px; */
}
.HomePage--content-Placement div {
  font-size: 14px;
  font-weight: normal;
}
.Placement-icon {
  font-size: 30px;
  font-weight: normal;
}
.Placement-icon {
  font-size: 30px;
}
@media only screen and (min-width: 300px) {
  .WomanONPCIMG {
    width: 95%;
    margin-left: 60px;
    margin-top: 25px;
     
  }
.selp{
  text-align: center;
}
}
.icon-container {
  display: flex;
  /* justify-content: center;
    align-items: center; */
  flex-direction: column;
  margin-bottom: 100px;
}
.icon-images {
  width: 110px;
  height: 80px;
}
@media only screen and (min-device-width: 280px) {
  .icon-image {
    width: 100%;
    height: 100%;
  }
}
.HomePage--content-footer {
  position: absolute;
  bottom: 0;
  background-color: #800080;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
   padding: 10px 40px;
  font-size: 14px;
  font-weight: 400;
  color: #fff !important;
}
.HomePage--content-footer div {
  white-space: nowrap;
}

.HomePage--content-footer a {
  color: #fff !important;
}

.form--Title-Name {
  display: flex;
  align-items: center;
}
.RegForm--title {
  margin-top: 7px;
}
.RegForm--icon {
  background-color: #800080;
  color: #ffffff;
  padding: 3px;
  margin-right: 30px;
  border-radius: 7px;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 400px) {
  .HomePage--Top-container {
    flex-direction: column-reverse;
  }
  .Badge-img-wrapper {
    margin: 0 0 30px 0;
  }
  .Badge-img {
    width: 150px;
  }
  .HomePage--content-card1 {
    flex: 6;
    padding: 50px 30px 10px 50px;
    /* display: flex;
    text-align: center;
    justify-content: center; */
    color: #212529;
  }
  .imgCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
  }
  .carousel-img {
    width: 200px !important;
    height: 200px !important;
  }
  .imgTextCont {
    width: 100% !important;
    text-align: left !important;
    padding: 10px !important;
    font-size: 16px !important;
    font-family: cursive;
  }
  .imgTextCont h2 {
    text-align: center;
  }
  .imgTextCont h6 {
    text-align: center;
  }
  .client-item-left {
    width: 100px !important;
    height: 40px !important;
    margin: -40px 20px 20px 20px !important;
    float: none !important;
  }
  .client-item-right {
    width: 100px !important;
    height: 40px !important;
    margin: -40px 20px 20px 20px !important;
    float: none !important;
  }
  .client-item-img {
    width: 100%;
    height: 100%;
    border-left: 1px solid black;
  }
  /* .intern-header-design h3::before{
        content: '';
        position: absolute;
        visibility: hidden;
    }
    .intern-header-design h3::after{
        content: '';
        position: absolute;
        visibility: hidden;
    } */
}
@media screen and (max-width: 767px) {
  .HomePage--Top-container {
    padding: 20px 20px 70px 20px;
  }
  .HomePage--content-card1,
  .HomePage--content-card2 {
    flex: 100%;
  }
  .HomePage--content-card2 {
    padding: 10px;
   
  }
  .HomePage--content-card1 {
    padding: 30px;
    padding-bottom: 0;
    display: flex;
    text-align: left;
    justify-content: center;
    color: #212529;
  }
  .HomePage--content-date-con {
    justify-content: center;
    padding: 30px 0;
  }
  .HomePage--content-button-con {
    text-align: center;
  }
  .HomePage--content-Placement {
    display: flex;
    align-items: center;
    margin: 10px;
  }
  .HomePage--content-button-con {
    padding: 0;
    /* text-align: center; */
  }
  .HomePage--content-button-contain {
    padding: 0;
    /* text-align: center; */
  }
  .Homepage--year {
    /* font-size: 5rem;
        font-weight: 600;
        color: #FFFFFF;
        margin-bottom: 7px;
        letter-spacing: 5px; */
  }
  .Homepage--role {
    /* font-size: 3rem;
        font-weight: 600;
        color: #FFFFFF;
        letter-spacing: 5px;
        text-transform: uppercase; */
  }
  .HomePage--Top- {
    /* line-height: 5rem; */
  }
  .imgCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
  }
  .carousel-img {
    width: 200px !important;
    height: 200px !important;
  }
  .imgTextCont {
    width: 100% !important;
    text-align: left !important;
    padding: 10px !important;
    font-size: 16px !important;
    font-family: cursive;
  }
  .imgTextCont h2 {
    text-align: center;
  }
  .imgTextCont h6 {
    text-align: center;
  }
  /* .intern-header-design h3::before{
        content: '';
        position: absolute;
        visibility: hidden;
    }
    .intern-header-design h3::after{
        content: '';
        position: absolute;
        visibility: hidden;
    } */
}
@media screen and (min-width: 767px) {
  .Badge-img-wrapper {
    margin: -15px 0 60px 60px;
  }
  .Badge-logo {
    width: 150px;
    position: absolute;
    left: 40px;
  }
  .Badge-img {
    width: 250px;
    position: absolute;
    right: 40px;
  }
  .HomePage--content-footer {
    justify-content: center !important;
    text-align: center;
  }
  .HomePage--content-footer div {
    flex: 100%;
    margin-bottom: 7px;
  }
  .HomePage--content-container {
    display: flex;
    flex-wrap: wrap;
  }
  .imgCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
  }
  .carousel-img {
    width: 200px !important;
    height: 200px !important;
  }
  .imgTextCont {
    width: 100% !important;
    text-align: left !important;
    padding: 10px !important;
    font-size: 16px !important;
    font-family: cursive;
  }
  .imgTextCont h2 {
    text-align: center;
  }
  .imgTextCont h6 {
    text-align: center;
  }
  .imgTextCont p {
    text-align: center;
  }
  /* .intern-header-design h3::before{
        content: '';
        position: absolute;
        visibility: hidden;
    }
    .intern-header-design h3::after{
        content: '';
        position: absolute;
        visibility: hidden;
    } */
}
@media screen and (max-width: 767px) and (min-width: 400px) {
  .client-item-left {
    width: 150px !important;
    height: 50px !important;
    margin: -40px 10px 20px 0 !important;
    float: none !important;
  }
  .client-item-right {
    width: 150px !important;
    height: 50px !important;
    margin: -40px 0 20px 0 !important;
    float: none !important;
  }
  .client-item-img {
    width: 100%;
    height: 100%;
    border-left: 1px solid black;
  }
}
@media screen and (max-width: 410px) {
  .Homepage--year {
    font-size: 30px;
    margin-bottom: 7px;
    letter-spacing: 5px;
  }
  .Homepage--role {
    font-size: 20px;
    letter-spacing: 5px;
    text-transform: uppercase;
  }
  .Homepage--Leadership {
    font-size: 20px;
  }
}

/*........ Desktop ........*/
.Desktop-Container {
  background-image: url(./images/hack1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100vwh;
  /* background-color: rgba(14, 13, 13, 0.7); */
}
.content2-time {
  font-size: 18px;
}
.mainContainer-content-Organizer a {
  color: lightblue;
}
.mainContainer-content-Organizer a:hover {
  color: #ffffff;
  text-decoration: none;
}
.Desktop-Wrapper {
  background: rgb(1, 1, 1);
  background: linear-gradient(
    103deg,
    rgba(1, 1, 1, 0) 0%,
    rgba(27, 25, 27, 1) 0%,
    rgba(54, 34, 54, 0.9402135854341737) 22%,
    rgba(119, 44, 119, 0.8393732492997199) 46%,
    rgba(130, 42, 130, 0.8309698879551821) 65%,
    rgba(130, 42, 130, 0.6825105042016807) 87%
  );
  backdrop-filter: blur(10px);
  /* height: 100vh; */
}
.mainImg-container {
  width: 100%;
  margin: 30px 0;
}
.mainImg {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 4px rgb(223, 213, 222);
}
.mainContainer-content-theme {
  font-size: 1.2rem;
  font-weight: bolder;
  color: #f0eeee;
  text-shadow: 2px 2px 10px rgb(39, 20, 38);
}
.mainContainer-content-Organizer {
  font-size: 1rem;
  font-weight: bold;
  color: #f0eeee;
  line-height: 30px;
  text-shadow: 2px 2px 10px rgb(39, 20, 38);
}
.list li {
  margin-bottom: 10px;
}
.but {
  background-color: #800080;
  border: 0px;
  border-radius: 5px;
  color: #ffffff;
  width: 80px;
  height: 40px;
}
.but:hover {
  background-color: #ffffff;
  border: 2px solid #800080;
  color: #800080;
}

.errBtn {
  background-color: rgb(209, 17, 17);
  padding: 5px 20px;
  display: inline-block;
  border-radius: 5px;
  color: #dbdbdb;
  margin-bottom: 10px;
}
.uploadButton {
  background-color: royalblue;
  padding: 5px 20px;
  display: inline-block;
  border-radius: 5px;
  color: #dbdbdb;
  margin-bottom: 10px;
}
.uploadingBtn {
  background-color: orange;
  padding: 5px 20px;
  display: inline-block;
  border-radius: 5px;
  color: #000;
  margin-bottom: 10px;
}
.uploadBtn {
  background-color: green;
  padding: 5px 20px;
  display: inline-block;
  border-radius: 5px;
  color: #dbdbdb;
  margin-bottom: 10px;
}
.write {
  font-size: 15px;
  text-align: justify;
  color: #fff;
}
.but1 {
  background-color: #800080;
  border: 2px solid #800080;
  color: #fff;
  font-size: 18px;
  border-radius: 5px;
  align-items: center;
  padding: 10px 0;
  width: 100%;
}
.but1:hover {
  background-color: #fff;
  border: 2px solid #800080;
  color: #800080;
}
.btn-cont {
  width: 100%;
}
.but2 {
  background-color: #dddcdc;
  border: 2px solid #dadada;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 2px 70px;
  height: 40px;
}
.but2:hover {
  cursor: pointer !important;
}
.read {
  /* text-decoration: underline; */
  font-weight: 700;
  background-color: transparent;
  color: lightblue;
  border: 0px;
}

.Content2-wrapper {
  text-align: right;
  font-size: 20px;
  padding: 5px;
  margin: 0 0 150px 0;
}
.Content2-wrapper .content2-time {
  margin: 0 20px 0 0;
  color: rgb(223, 213, 222);
  text-shadow: 2px 2px 10px rgb(122, 33, 118);
  font-weight: 400;
}
.content2Icon {
  color: rgb(223, 213, 222) !important;
  margin: 10px 0;
}
.content2-icon-box {
  box-shadow: 2px 2px 10px rgb(68, 25, 66) !important;
  padding: 7px;
  border-radius: 10px;
}
.Checkbox-form-con {
  margin: 10px 0;
  font-size: 12px !important;
  text-transform: capitalize;
}
.modal-content {
  font-size: 14px;
  font-weight: bold;
}
.TnD-Header {
  margin: 10px 0;
  font-size: 16px;
}
.modal-TndD-Title {
  text-transform: capitalize;
}
.checkBox-tick {
  margin: 10px 0;
}
.TnC {
  font-size: 12px !important;
}
.Checkbox-form-CT {
  /* margin: 0 3px ; */
  color: #800080;
  cursor: pointer;
}

/*....... WelcomeNote WAPPER .........*/
.WelcomeNote-background-wrapper {
  background-image: url(/src/images/lagos-techie-IgUR1iX0mqM-unsplash.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.WelcomeNote-background-container {
  background-color: #800080c7;
  width: 100%;
  padding: 50px;
}
.WelcomeNote-title-name {
  font-weight: bolder;
  font-size: 52px;
  color: #dadada;
  margin-bottom: 20px;
}
.WelcomeNote-title-card {
  margin-bottom: 30px;
}
.WelcomeNote-title-content {
  font-size: 32px;
  font-weight: bold;
  color: #dadada;
  margin-bottom: 2px;
}

.WelcomeNote--flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.ApplyHere-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.applyHereIcon {
  font-size: 58px;
  color: #dadada;
}
.ApplyHere-container button {
  padding: 15px 70px;
  margin-left: 10px;
  font-size: 24px;
  font-weight: bolder;
  background-color: #dadada;
  border: 3px solid #800080;
  color: #800080;
  border-radius: 5px;
}

.uploadBtn,
.uploadButton,
.uploadingBtn,
.errBtn {
  cursor: pointer;
}

.Welcome-content-Wrapper {
  background-color: #ffffff;
  padding: 50px;
  margin-top: 30px;
  /* margin-bottom: 60px; */
}
.Welcome-content-Wrapper-1 {
  /* background-color: #000; */

  padding-left: 50px, 0;

  /* margin-top: 30px; */

  background-image: linear-gradient(transparent, #ffffff 100%),
    url(./images/laptop.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  margin-bottom: 30px; /*remove this bottom-margin to make the background-image to merge without space*/

  /* margin-bottom: 60px; */
}

.Welcome-content-card {
  color: #333333;
  /* border-left: 3px solid #3333339c; */
  padding: 20px;
}
.Welcome-content-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 32px;
  color: #00c9b7 !important;
}
.Welcome-content-subtitle {
  font-size: 18px;
  font-weight: bolder;
  line-height: 24px;
  margin-bottom: 7px;
}
.Welcome-content-subtitle-con {
  font-size: 18px;
  font-weight: bolder;
  line-height: 26px;
  margin-bottom: 7px;
}
.Welcome-content-subtitle-con-bottom {
  margin-top: 40px;
}
.Welcome-content-content {
  line-height: 24px;
  font-size: 16px;
}
.Welcome-content-title-card-content {
  margin-bottom: 40px;
  line-height: 24px;
}

.Welcome-datetime-container {
  padding-right: 20%;
  padding-left: 15px;
  position: relative;
  z-index: 2;
}
.Welcome-datetime-liner {
  height: 2px;
  width: 100%;
  background-color: #c5c5c5;
  position: relative;
  z-index: 3;
}
.Welcome-datetime-wrapper {
  display: flex;
  margin-inline: 2rem;
  flex: 6;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #ffffff;
  color: #800080;
  padding-bottom: 20px;
  width: fit-content;
  height: fit-content;
  border: 1px solid #c5c5c5;
  margin-top: -40px;
  padding-left: 20px;
}
.welcome--date {
  font-size: 26px;
  font-weight: bolder;
  margin-inline: 0rem;
}
.welcome--time {
  font-size: 16px;
  font-weight: bold;
}
.welcome--content {
  color: #333333;
}
.welcome--content button {
  border: 1px solid #2c7777;
  padding: 6px;
  font-size: 14px;
  border-radius: 2px;
  background-color: #2c7777;
  color: #fff;
}
.welcome--content button:hover {
  background-color: #fff;
  box-shadow: 0 0 20px 4px rgba(94, 94, 94, 0.466);
  transition: 2s;
  color: #2c7777;
}
.welcome--meetup-container {
  display: flex;
  justify-content: center;
  padding: 100px 0 50px 0;
  background-color: #fa8ffa13;
}
.welcome--meetup-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}
.welcome--meetup-container {
  /* margin: 100px 0;  */
}
.welcome--meetup-wrapper .welcome--meetup-card1,
.welcome--meetup-wrapper .welcome--meetup-card2 {
  flex: 1;
  width: 300px;
  margin-bottom: 20px;
}
.welcome--meetup-card1 button {
  border: none;
  background-color: rgb(5, 148, 214);
  padding: 30px;
  font-size: 26px;
  font-weight: bolder;
  color: whitesmoke;
  border-radius: 7px;
  position: relative;
  margin-bottom: 15px;
}
.welcome--meetup-card1 button:hover {
  background-color: rgba(4, 71, 102, 0.719);
  transition: 2s;
  box-shadow: 0 0 20px 4px rgba(94, 94, 94, 0.466);
}
.welcome--meetup-applyNow-icon {
  position: absolute;
  bottom: -20px;
  right: 7px;
  font-size: 52px;
  color: #333333;
}
.welcome--meetup-Deadline {
  font-size: 18px;
  color: rgba(202, 6, 6, 0.938);
  font-weight: 500;
  font-style: italic;
}
.welcome--meetup-time {
  font-size: 16px;
  color: #333333;
}
.welcome--meetup-card2 {
  border-left: 3px solid #333333;
  padding-left: 10px;
}
.welcome--meetup-card2-content {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.welcome--meetup-card2-icon {
  font-size: 48px;
  color: #333333;
}
.welcome--meetup-card2-content-text {
  font-size: 18px;
  font-weight: bolder;
  margin-left: 7px;
}
.welcome--meetup-card1 {
}
.welcome--meetup-card2 {
}
.faq--con {
  margin-left: 30px;
}
.faq--cont {
  margin-bottom: 100px;
  width: 100%;
  padding: 0;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  border-radius: 5px;
  background-color: white;
  /* margin-left: 10px; */
}

.faq-title {
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
  width: 100%;
}
.faq-title h2 {
  color: #580258 !important;
 
}
.row-title-text {
}

/*......... FOOTER .........*/
.BodyFrame--footer {
  background-color: #ffffff;
  padding: 50px 20px;
  border-top: 2px solid rgba(151, 151, 151, 0.267);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #800080;
}
.BodyFrame--footer-title {
  font-size: 48px;
  font-weight: bolder;
  color: #800080 !important;
}
.connect-with-us-card {
  display: flex;
}
.BodyFrame--footer-subtitle {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333333;
}
.connect-icon {
  font-size: 32px;
  margin: 0 7px;
  color: #333333;
}
.connect-icon:hover {
  color: #800080;
}

.submit2 {
  width: 100%;
  /* align-self: center;
  justify-self: center; */
  padding: 10px;
  font-weight: bolder;
  /* margin-left: 15%; */
  font-size: 18px;
  color: #fff;
  background-color: #2c7777;
  border-radius: 5px;
  border: 1px solid #2c7777;
}

.submit2:hover {
  background-color: white;
  border: 2px solid #2c7577;
  color: #2c7577;
  box-shadow: 0 0 20px 4px rgba(94, 94, 94, 0.466);
}
.submit2-disable {
  width: 100%;
  padding: 11px 0;
  font-weight: bolder;
  font-size: 18px;
  color: #fff;
  background: #800080;
  border-radius: 5px;
  border: 1px solid #800080;
  cursor: not-allowed;
}
.submit2-disable:hover {
  color: #800080;
  background: #ffffff;
  border: 1px solid #800080;
}
.Welcome-content-subtitle-container li {
  margin-bottom: 8px;
  font-size: 16px;
}

@media screen and (max-width: 1279px) {
  .Welcome-datetime-container {
    padding: 0 3rem;
  }
  .Welcome-datetime-container-1 {
    padding: 0 1rem;
  }
}
@media screen and (max-width: 1169px) {
  .ApplyHere-container {
    justify-content: center;
    text-align: center;
    width: 100%;
    margin: 30px 0;
  }
}

@media screen and (max-width: 767px) {
  .WelcomeNote-background-container,
  .Welcome-content-Wrapper {
    padding: 30px 20px;
  }
  .WelcomeNote-title-content {
    font-size: 26px;
  }
  .Welcome-content-title {
    font-size: 30px;
  }
  .Welcome-datetime-container {
    padding: 10px;
  }
  .Welcome-datetime-container-1 {
    /* padding: 10px; */
  }
  .Welcome-datetime-wrapper .Welcome-datetime-card {
    width: 100%;
  }
  .Welcome-datetime-card-bottom {
    margin-bottom: 17px;
  }
  .welcome--meetup-wrapper .welcome--meetup-card1,
  .welcome--meetup-wrapper .welcome--meetup-card2 {
    flex: 100%;
    width: 300px;
  }
  .welcome--meetup-card2 {
    border-left: none;
  }
  .connect-with-us-card2 {
    width: 100%;
  }
  .connect-with-us-card {
    justify-content: space-between;
  }
  .BodyFrame--footer-title {
    margin-bottom: 30px;
  }
  .welcome--meetup-card2 {
    padding: 0 40px;
  }
  .welcome--date {
    position: relative;
    bottom: 8px;
  }
}
@media screen and (max-width: 539px) {
  .WelcomeNote-title-name {
    font-size: 40px;
  }
  .applyHereIcon {
    font-size: 40px;
    color: #dadada;
  }
  .ApplyHere-container button {
    padding: 15px 70px;
    margin-left: 7px;
    font-size: 16px;
  }
  .Welcome-content-card {
    padding: 20px 10px;
  }
  .Welcome-content-title {
    font-size: 24px;
  }
}

@media screen and (max-width: 410px) {
  .BodyFrame--footer-title {
    font-size: 42px;
  }
}
@media screen and (max-width: 359px) {
  .ApplyHere-container button {
    padding: 10px 30px;
    margin-left: 7px;
    font-size: 14px;
  }
  .WelcomeNote-title-name {
    font-size: 32px;
  }
  .WelcomeNote-title-content {
    font-size: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .BodyFrame-container {
    display: flex;
    justify-content: center;
  }
  .BodyFrame {
    max-width: 1440px;
  }
}
@media screen and (min-width: 1280px) {
  .WelcomeNote-background-wrapper,
  .WelcomeNote-background-container {
    height: 500px;
  }
  .Welcome-content-card {
    width: 80%;
  }
}

@media screen and (min-width: 768px) {
  .Welcome-datetime-wrapper .Welcome-datetime-card {
    flex: 1;
  }
  .Welcome-content-title {
    line-height: 45px;
  }
}

@media screen and (min-width: 700px) and (max-width: 1200px) {
  .modal-dialog {
    margin-top: 150px !important;
  }
}
@media screen and (min-width: 300px) {
  .modal-backdrop.show {
    opacity: 0 !important;
    background-color: transparent !important;
  }
  .modal-dialog {
    margin: 10% auto;
  }
  .modal-backdrop {
    opacity: 0 !important;
    height: 0 !important;

    background-color: transparent !important;
  }

  .write {
    font-size: 15px;
    text-align: justify;
    color: #fff;
  }
  .top-menu-Container {
    border-bottom: 1px solid rgba(122, 33, 117, 0.048);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    /* background-color: rgba(122, 33, 117, 0.048); */
  }
  .top-menu-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 5px 15px 0 15px;
  }
  .top-menu-list-wrapper {
    display: flex;
    flex-wrap: nowrap;
    list-style-type: none;
    margin-top: 9px;
  }
  .top-menu-list-wrapper li {
    padding: 10px 20px;
    /* background-color: rgba(122, 33, 117, 0.048); */
    border-radius: 3px;
    box-shadow: 0px 0px 4px rgb(122, 33, 118);
    margin: 0 10px;
    font-weight: bold;
  }
  .top-menu-list-link {
    text-decoration: none;
    color: #454646d5;
  }
  .top-menu-list-link:hover {
    text-decoration: none;
    color: rgba(122, 33, 117, 0.644);
  }
  /*........ LOGO .......*/
  .logo-Container {
    font-size: 16px;
    font-weight: 700;
  }
  .logo-Container {
    width: 100px;
  }
  .logo-img-container {
    width: 20px;
  }
  .logo-img {
    width: 90px;
  }
  /*........ FOOTER .......*/

  /*........ Footer Top .......*/
  .footer-container {
    /* border-top: 2px solid rgba(0, 0, 0, 0.1); */
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    bottom: 0;
    position: relative;
    margin-bottom: 0;
    /* background-color: rgba(122, 33, 117, 0.048); */
  }
  .top-footer {
    display: flex;
    justify-content: space-between;
    padding: 30px 50px 30px 20px;
  }
  .top-footer-list {
    display: flex;
    flex-wrap: nowrap;
    list-style-type: none;
  }
  .top-footer-list li {
    margin: 0 10px;
    padding: 10px;
    font-weight: bold;
    color: #454646d5;
    border-bottom: 1px solid rgb(122, 33, 118);
  }
  .top-footer-list li:hover {
    border: 1px solid rgb(122, 33, 118);
    box-shadow: 0px 0px 4px rgb(122, 33, 118);
    border-radius: 5px;
  }
  /*........ FOOTER LOGO .......*/
  .footer-logo-Container {
    font-size: 16px;
    font-weight: 700;
    padding-left: 40px;
  }
  .footer-logo-Container {
    width: 100px;
  }
  .footer-logo-img-container {
    width: 0px;
  }
  .footer-logo-img {
    width: 70px;
  }

  .bottom-footer {
    background-color: rgb(122, 33, 118);
    width: 100vw;
    text-align: center;
    padding: 30px 0;
    color: #ffffff;
    font-weight: bold;
  }
  /*............. Desktop Container .........*/
  .Desktop-Container {
    background-image: url(./images/hack1.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100vw;
    /* background-color: rgba(14, 13, 13, 0.7); */
  }
  .Desktop-Wrapper {
    background: rgb(1, 1, 1);
    background: linear-gradient(
      103deg,
      rgba(1, 1, 1, 0) 0%,
      rgba(27, 25, 27, 1) 0%,
      rgba(54, 34, 54, 0.9402135854341737) 22%,
      rgba(119, 44, 119, 0.8393732492997199) 46%,
      rgba(130, 42, 130, 0.8309698879551821) 65%,
      rgba(130, 42, 130, 0.6825105042016807) 87%
    );
    /* backdrop-filter: blur(10px); */
  }
  .mainImg-container {
    width: 70%;
    margin: 30px 10px;
  }
  .mainImg {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgb(68, 25, 66);
  }
  /*........ Content 1 .............*/

  .mainContainer-content-theme {
    font-size: 1.8rem;
    font-weight: bolder;
    color: #f0eeee;
    text-shadow: 2px 2px 10px rgb(39, 20, 38);
  }
  .mainContainer-content-Organizer {
    font-size: 1.4rem;
    font-weight: bold;
    color: #f0eeee;
    text-shadow: 2px 2px 10px rgb(39, 20, 38);
  }
  .write {
    color: #ffffff;
  }
  /* @media screen and (max-width: 900px) { */
  .modal-backdrop.show {
    opacity: 0 !important;
  }
  .modal-backdrop {
    opacity: 0 !important;
    height: 0 !important;
    background-color: transparent !important;
  }
  /* } */
}
.mainContainer-content-Organizer span {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 50px;
  color: rgb(223, 213, 222);
  text-shadow: 2px 2px 10px rgb(122, 33, 118);
}
.mainContainer {
  margin-bottom: 20px;
  margin-top: 0;
  /* height: 2000vh; */
}
/*........ Content 2 .............*/
.Content2-wrapper {
  text-align: right;
  font-size: 30px;
  padding: 30px 0;
}
.Content2-wrapper div {
  margin: 10px 0;
}
.Content2-wrapper .content2-time {
  margin: 0 20px 0 0;
  color: rgb(223, 213, 222);
  text-shadow: 2px 2px 10px rgb(122, 33, 118);
  font-weight: bold;
}
.content2Icon {
  color: rgb(223, 213, 222) !important;
  margin: 10px 0;
}
.content2-icon-box {
  box-shadow: 2px 2px 10px rgb(68, 25, 66) !important;
  padding: 7px;
  border-radius: 10px;
}

.bottom-footer {
  background-color: rgb(122, 33, 118);
  width: 100vw;
  text-align: center;
  padding: 30px 0;
  color: #ffffff;
  font-weight: bold;
}
.imgCont {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 4px solid #fff;
  margin: 0 auto 15px auto;
  padding: 10px;
}
.carousel-img {
  height: 300px;
  width: 300px;
  margin: 0 auto;
  border: 4px solid white;
  border-radius: 50%;
}
.graduate-img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
}
.imgTextCont {
  text-align: center;
  font-family: cursive;
  margin: 10px 0 5px 0;
}
.imgTextCont p {
  font-size: 18px;
  font-weight: 900;
  text-align: justify;
}
/* .intern-header-design h3::before{
    content: '';
    position: absolute;
    width: 120px;
    height: 1px;
    background-color: #c5c5c5;
    top: 50px;
    left: 45%;
}
.intern-header-design h3::after{
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background-color: #25011c;
    top: 49px;
    left: 48%;
} */
/* #owl-client{
    display: flex;
    margin-left: 100px;
    margin-right: 100px;
    padding-left: 0;
    align-self: center;
    align-items: center;
} */
.client-item-left {
  width: 300px;
  height: 70px;
  margin: -20px 20px 30px 20px;
  float: right;
}
.client-item-right {
  width: 300px;
  height: 70px;
  margin: -20px 20px 30px 20px;
  float: left;
}
.client-item-img {
  width: 100%;
  height: 100%;
  border-left: 1px solid black;
}
.card-img-block1 {
  width: 70px;
  height: 40px;
  position: absolute;
  top: 340px;
  background-color: white;
  float: left;
}
.card-img-block-21 {
  width: 2px;
  height: 380px;
  position: absolute;
  right: 150px;
  background-color: white;
}
.card-img-block-1 {
  width: 400px;
  height: 1px;
  position: absolute;
  top: 360px;
  margin-left: 70px;
  background-color: white;
}
.testimonial {
  text-align: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  font-style: inherit;
}
.card-img-block2 {
  width: 70px;
  height: 40px;
  position: absolute;
  top: 380px;
  background-color: white;
  float: left;
}
.card-img-block-212 {
  width: 2px;
  height: 420px;
  position: absolute;
  right: 70px;
  background-color: white;
}
.card-img-block-12 {
  width: 400px;
  height: 1px;
  position: absolute;
  top: 400px;
  margin-left: 70px;
  background-color: white;
}
.card-img-block-2 {
  width: 2px;
  height: 380px;
  position: absolute;
  left: 50px;
  background-color: white;
}
.card-img-block3 {
  width: 100px;
  height: 40px;
  position: absolute;
  top: 420px;
  background-color: white;
  float: left;
}
.card-img-block-13 {
  width: 400px;
  height: 1px;
  position: absolute;
  top: 440px;
  margin-left: 70px;
  background-color: white;
}
.card-img-block-31 {
  width: 2px;
  height: 460px;
  position: absolute;
  right: 150px;
  background-color: rgb(204, 204, 204);
}

#exampleModalCenter {
  position: fixed;
  margin-top: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.144);
  padding: 0;
  margin: 0;
}
